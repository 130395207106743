import { useState } from "react";
import { MdClose } from "react-icons/md";
import { AiOutlineExpandAlt, AiOutlineShrink } from "react-icons/ai";
import { useAuth0 } from "@auth0/auth0-react";

export default function GraphContainer({
	removeGraph,
	title,
	children,
	selectedOption,
	selection,
	setSelection,
	expandedByDefault,
	allowResize = true,
	hasAsBuilt = false,
	isPublicStorage = false,
}) {
	const [expand, setExpand] = useState(expandedByDefault ? true : false);
	const { user } = useAuth0();

	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setSelection((prev) => ({ ...prev, [name]: checked }));
	};

	return (
		<div className={`flex flex-col items-around justify-between bg-white border border-black rounded-lg overflow-hidden ${expand ? "col-span-2" : ""}`}>
			<div className="flex justify-between  p-2 bg-black text-white ">
				<div />
				<h2 className="text-lg font-bold">{title}</h2>
				<div className="flex gap-1">
					{!allowResize ? null : expand ? (
						<button onClick={() => setExpand(false)}>
							<AiOutlineShrink size={25} />
						</button>
					) : (
						<button onClick={() => setExpand(true)}>
							<AiOutlineExpandAlt size={25} />
						</button>
					)}
					{removeGraph ? (
						<button onClick={() => removeGraph()} className="hover:font">
							<MdClose size={25} />
						</button>
					) : null}
				</div>
			</div>

			<div className="p-2">
				{children}
				<div className="flex items-center justify-center gap-2">
					<form className="flex gap-4 items-center">
						<div>
							<label className="flex items-centers gap-1">
								<input type="checkbox" name="measured" checked={selection.measured} onChange={handleCheckboxChange} />
								Measured
							</label>
						</div>
						{isPublicStorage || user.email === "ess@1stle.com" ? (
							<div>
								<label className="flex items-centers gap-1">
									<input type="checkbox" name="expected" checked={selection.expected} onChange={handleCheckboxChange} />
									UW Expected
								</label>
							</div>
						) : null}

						{hasAsBuilt ? (
							<div>
								<label className="flex items-centers gap-1">
									<input type="checkbox" name="asBuilt" checked={selection.asBuilt} onChange={handleCheckboxChange} />
									As Built
								</label>
							</div>
						) : null}
					</form>
				</div>
			</div>
		</div>
	);
}
