import { useAuth0 } from "@auth0/auth0-react";
import Overview from "./Overview";
import { useEffect, useRef, useState } from "react";
import Authentication from "./Authentication";
import SitesDocs from "./SitesDocs";
import SiteDocs from "./SiteDocs";
import MetersDocs from "./MetersDocs";
import MeterDocs from "./MeterDocs";
import DataDocs from "./DataDocs";

const APIDocs = ({ api, token, sidebarOpen }) => {
	const { user } = useAuth0();

	useEffect(() => {
		document.title = "Customer Portal API Docs v1 | 1st Light Energy";
		return () => {
			document.title = "Customer Portal";
		};
	}, []);

	const sectionsRefs = useRef([]);
	const [activeId, setActiveId] = useState("");

	const sections = [
		{ id: "overview", Component: Overview },
		{ id: "authentication", Component: Authentication },
		{ id: "sites", Component: SitesDocs },
		{ id: "site", Component: SiteDocs },
		{ id: "meters", Component: MetersDocs },
		{ id: "meter", Component: MeterDocs },
		{ id: "meter-data", Component: DataDocs },
	];

	const handleScroll = () => {
		const viewportHeight = window.innerHeight;

		// Iterate through sections to find the active one
		sectionsRefs.current.forEach((ref, index) => {
			if (ref) {
				const rect = ref.getBoundingClientRect();
				const isVisible = rect.top >= 0 && rect.top <= viewportHeight / 2;

				if (isVisible) {
					setActiveId(ref.id);
				}
			}
		});
	};

	useEffect(() => {
		// Attach scroll event listener
		window.addEventListener("scroll", handleScroll);

		return () => {
			// Cleanup on unmount
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}
			/>

			<div className={`${sidebarOpen ? "md:ml-64" : "md:ml-10"} z-[100] `}>
				<div className="bg-slate-50 flex justify-center opacity-95 min-h-screen">
					{/* <div className="flex ">
						<div className="w-full p-4">white</div>
						<div className="bg-black w-full p-4 text-white">black</div>
					</div> */}
					<div className="flex flex-col-reverse md:flex-row w-full md:w-4/5 ">
						{/* <div className="p-4">
							<p className="font-bold text-2xl">Contents</p>
							<div>
								<p>Overview</p>
							</div>
							<div>
								<p>Sites</p>
							</div>
							<div>
								<p>Meters</p>
							</div>
							<div>
								<p>Data</p>
							</div>
						</div> */}
						<div className="flex-grow md:basis-3/4">
							<h1 className="font-bold text-3xl text-center mb-2 py-2 pt-4">{`HTTP API Documentation (V1)`}</h1>
							<hr />
							{sections.map(({ id, Component }, index) => (
								<section id={id} key={id} ref={(el) => (sectionsRefs.current[index] = el)}>
									<Component />
								</section>
							))}
						</div>
						<div className="md:basis-1/4 relative">
							<div className="sticky top-0 p-4 ">
								<div className="">
									<p className="font-bold  text-2xl mb-3">On This Page</p>
									<div className="flex flex-col gap-2 px-4">
										{[
											{ id: "overview", label: "API Overview" },
											{ id: "authentication", label: "How to Authenticate" },
											{ id: "sites", label: "Get All Sites" },
											{ id: "site", label: "Get Specific Site Information" },
											{ id: "meters", label: "Get All Meters" },
											{ id: "meter", label: "Get Specific Meter Information" },
											{ id: "meter-data", label: "Get Meter Data" },
										].map((item) => (
											<p key={item.id}>
												<a
													href={`#${item.id}`}
													style={{
														fontWeight: activeId === item.id ? "bold" : "normal",
													}}
													className={`${activeId === item.id ? "" : ""}`}>
													{item.label}
												</a>
											</p>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default APIDocs;
