import { CodeBlock, dracula } from "react-code-blocks";
import JSONPretty from "react-json-pretty";

const SitesDocs = () => {
	return (
		<section>
			<h2 className="p-4 bg-slate-200 font-bold text-3xl">Get All Sites</h2>
			<div className="p-4">
				<div className="mt-4 flex flex-col gap-4">
					<div className="mb-4">
						<p>A way to view all available sites you have access to. Returns a maximum of 100 per page.</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Definition</p>
						<p>
							<span className="bg-gray-200">/sites</span>
						</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Arguments</p>
						<div className="grid grid-cols-4 border border-black">
							<p className="p-1 bg-slate-200 border-b border-black">Argument</p>
							<p className="p-1 bg-slate-200 border-b border-black">Description</p>
							<p className="p-1 bg-slate-200 border-b border-black">Required?</p>
							<p className="p-1 bg-slate-200 border-b border-black">Default</p>
							<p className="p-1">page</p>
							<p className="p-1">The page to return</p>
							<p className="p-1">No</p>
							<p className="p-1">1</p>
						</div>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Returns</p>
						<p>
							<span className="mr-1 font-bold">sites:</span> A list of objects showing the site's id and name.
						</p>
						<p>
							<span className="mr-1 font-bold">totalPages:</span> The number of pages that have results.
						</p>
					</div>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example Request</div>
						<div className="p-2">
							<p>GET /sites?page=1</p>
							<p>{`headers: {"Authorization": "Bearer YOUR_TOKEN"}`}</p>
						</div>
					</div>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example JSON Response</div>
						<div className="p-2">
							<JSONPretty
								data={{
									sites: [
										{
											id: 1,
											name: "Example Site 1",
											meter_ids: [1],
										},
										{
											id: 2,
											name: "Example Site 2",
											meter_ids: [2, 3],
										},
										{
											id: 3,
											name: "Example Site 3",
											meter_ids: [4, 5, 6],
										},
									],
									totalPages: 1,
								}}
							/>
						</div>
					</div>

					<hr className="border border-gray-400 mt-4" />
					<div className="mt-4">
						<p className="font-bold text-lg">Example Implementation</p>
						<p>
							This example will show you how to get all your sites in python. Since this is limited to 100 at a time, we have to use the
							totalPages info from the results to get all of them.
						</p>
						<div className="bg-black text-white border border-black mt-4 mb-4">
							<CodeBlock
								text={`import requests
import json

// token code

token = get_token()

def get_sites():
	route='/sites?page='
	page_no=1
	headers = {'Authorization': 'Bearer ' + token}
	sites = []

	while True:
		res = requests.get(f'{BASE_URL}{route}{page_no}', headers=headers)
		if res.status_code != 200:
			raise Exception(f"API call failed with status code {res.status_code}: {res.text}")

		data = res.json()
		sites.extend(data.get('sites', []))  

		total_pages = data.get('totalPages', 1)
		if page_no >= total_pages:
			break  

		page_no += 1  
	return sites

sites = get_sites()`}
								language="python"
								theme={dracula}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default SitesDocs;
