import { CodeBlock, dracula } from "react-code-blocks";
import JSONPretty from "react-json-pretty";

const MeterDocs = () => {
	return (
		<section>
			<h2 className="p-4 bg-slate-200 font-bold text-3xl">Get Specific Meter Information</h2>
			<div className="p-4">
				<div className="mt-4 flex flex-col gap-4">
					<div className="mb-4">
						<p>Get more detailed information on a single meter.</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Definition</p>
						<p>
							<span className="bg-gray-200">{`/meter/{meterId}`}</span>
						</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Arguments</p>
						<div className="grid grid-cols-4 border border-black">
							<p className="p-1 bg-slate-200 border-b border-black">Argument</p>
							<p className="p-1 bg-slate-200 border-b border-black">Description</p>
							<p className="p-1 bg-slate-200 border-b border-black">Required?</p>
							<p className="p-1 bg-slate-200 border-b border-black">Default</p>
							<p className="p-1">meterId</p>
							<p className="p-1">The id of the meter</p>
							<p className="p-1">yes</p>
							<p className="p-1"></p>
						</div>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Returns</p>
						<p>
							<span className="mr-1 font-bold">meter:</span> A more detailed look at an individual meter.
						</p>
					</div>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example Request</div>
						<div className="p-2">
							<p>GET /meters/1</p>
							<p>{`headers: {"Authorization": "Bearer YOUR_TOKEN"}`}</p>
						</div>
					</div>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example JSON Response</div>
						<div className="p-2">
							<JSONPretty
								data={{
									meter: {
										id: 121,
										site_id: 115,
										hardware: [
											{
												type: "locus",
												id: 1,
											},
										],
									},
								}}
							/>
						</div>
					</div>
					<hr className="border border-gray-400 mt-4" />
					<div className="mt-4">
						<p className="font-bold text-lg">Example Implementation</p>
						<p>This example will show you how to use the route via Python.</p>
						<div className="bg-black text-white border border-black mt-4 mb-4">
							<CodeBlock
								text={`import requests
import json

// token code

token = get_token()

meter_id = 1 # PLACE METER ID HERE

def get_meter(meter_id):
	route='/meters/' + str(meter_id)
	headers = {'Authorization': 'Bearer ' + token}

	res = requests.get(BASE_URL+route, headers=headers)
	if res.status_code != 200:
		raise Exception(f"API call failed with status code {res.status_code}: {res.text}")

	data = res.json()
	meter = data.get('meter', False)
	if(meter):
		return meter
	else:
		raise Exception('Meter not found')

meter = get_meter(meter_id)`}
								language="python"
								theme={dracula}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default MeterDocs;
