const Overview = () => {
	return (
		<section>
			<h2 className="p-4 bg-slate-200 font-bold text-3xl">API Overview</h2>
			<div className="p-4">
				<p className="font-bold text-xl mb-4">Introduction</p>
				<p>
					The Customer Portal V1 API endpoint prefix is <span className="bg-gray-200">{`${process.env.REACT_APP_PUBLIC_API_URL}/api/v1`}</span>.
				</p>
				<p className="mt-3">
					The API allows you to programmatically access data displayed in the customer portal. They can be divided into 3 categories:
				</p>
				<div className="p-4 px-5">
					<ol className="list-disc flex flex-col gap-2">
						<li>
							<span className="font-bold mr-2">Sites:</span> A site is a container for the meters. It contains information on location, how many
							meters are at the location, and name of the site among other things.
						</li>
						<li>
							<span className="font-bold mr-2">Meters:</span>A meter represents devices installed at each site. These objects contain information
							on the meter type and kw installed.
						</li>
						<li>
							<span className="font-bold mr-2">Data:</span>Data collected from each meter. Returns collection time, measured data, meter collected
							from, and expected data.
						</li>
					</ol>
				</div>
				<p className="mt-2">Data is returned in the JSON format.</p>
			</div>
			<hr className="border border-gray-400" />
			<div className="p-4">
				<p className="font-bold text-xl mb-2">Versioning</p>
				<p>
					This API is versioned, ensuring backward compatibility. You won't need to make changes on your end due to updates, as existing functionality
					will remain intact without removals or disruptive modifications. If such changes are made, we will move to a different version but keep the
					current version intact. If any changes are made to this version, it will be to extend the data we return as opposed to removing anything.
					Those will be noted below in the change log.
				</p>
			</div>

			<hr className="border border-gray-400" />
			<div className="p-4">
				<p className="font-bold text-xl mb-2">Change Log</p>
				<p>12-23-2024</p>
				<ol className="list-disc px-5">
					<li>First implementation of the API created.</li>
				</ol>
			</div>
		</section>
	);
};

export default Overview;
