import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import FilterComponent from "../Filters/FilterComponent";
import LoadingCircle from "../../Utility/LoadingCircle";

function escapeCsvValue(value) {
	if (typeof value === "string") {
		value = value.replace(/"/g, '""');
		if (value.includes(",") || value.includes('"')) {
			return `"${value}"`;
		}
	}
	return value;
}

function formatCSV(data, startDate, endDate, exportLevel, granularity) {
	let csvContent = "";
	let site = "";

	if (exportLevel === "Sites") {
		site = "Site Name";
	} else {
		site = "Component";
	}

	if (granularity === "Yearly") {
		csvContent = `Date,${site},Type,Measured for Year,UW Expected for Year\n`;
	} else if (granularity === "Monthly") {
		csvContent = `Date,${site},Type,Measured for Month,UW Expected for Month\n`;
	} else {
		csvContent = `Date,${site},Type,Measured for Day,UW Expected for Day\n`;
	}

	data.forEach((location) => {
		const { name, monitoringData, expectedTableData } = location;
		const dateMap = new Map();
		const isWithinRange = (date) => {
			const currentDate = new Date(date);

			const startUTC = new Date(startDate);
			startUTC.setUTCHours(0, 0, 0, 0);

			const endUTC = new Date(endDate);
			endUTC.setUTCHours(23, 59, 59, 999);

			return currentDate >= startUTC && currentDate <= endUTC;
		};
		const filteredMeasuredData = startDate && endDate ? monitoringData.filter(({ ts }) => isWithinRange(ts)) : monitoringData;
		const filteredExpectedData = startDate && endDate ? expectedTableData.filter(({ date }) => isWithinRange(date)) : expectedTableData;

		const today = new Date().toISOString().slice(0, 10);

		filteredMeasuredData.forEach(({ ts: dateStr, kwh_measured, type }) => {
			const formattedDate = new Date(dateStr).toISOString().slice(0, 10);
			if (formattedDate > today) return;
			if (!dateMap.has(formattedDate)) {
				dateMap.set(formattedDate, {});
			}
			const typeMap = dateMap.get(formattedDate);

			if (typeMap[type]) {
				typeMap[type].measured += kwh_measured;
			} else {
				typeMap[type] = { measured: kwh_measured, expected: 0 };
			}
		});

		filteredExpectedData.forEach(({ date: dateStr, value, type }) => {
			const formattedDate = new Date(dateStr).toISOString().slice(0, 10);
			const roundedKwh = Math.round(value);
			if (formattedDate > today) return;
			if (!dateMap.has(formattedDate)) {
				dateMap.set(formattedDate, {});
			}
			const typeMap = dateMap.get(formattedDate);

			if (typeMap[type]) {
				typeMap[type].expected += roundedKwh;
			} else {
				typeMap[type] = { measured: 0, expected: roundedKwh };
			}
		});

		dateMap.forEach((typeMap, date) => {
			Object.entries(typeMap).forEach(([type, value]) => {
				if (value.measured > 0 || (value.expected > 0 && value.measured > 0)) {
					csvContent += `${date},${escapeCsvValue(name)},${type},${value.measured},${value.expected}\n`;
				}
			});
		});
	});
	return csvContent;
}

const downloadCSV = (csvString, filename = "data.csv") => {
	const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default function ExportPage({ api, sidebarOpen, token, isCommercial }) {
	const { user } = useAuth0();
	const { id } = useParams();
	const navigate = useNavigate();
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [errorText, setErrorText] = useState("");
	const [filterMonitoringType, setFilterMonitoringType] = useState(false);
	const [monitoringFilter, setMonitoringFilter] = useState("All");
	const [monitoringOptions, setMonitoringOptions] = useState([]);
	const [exportLevel, setExportLevel] = useState("Sites");
	const [granularity, setGranularity] = useState("Daily");
	const [ptoFilter, setPtoFilter] = useState("All");
	const exportOptions = ["Sites", "Meters"];
	const granularityOptions = ["Daily", "Monthly", "Yearly"];
	const ptoOptions = ["All", "PTO Approved", "No PTO"];
	const [parent, setParent] = useState([]);
	const [fetchingData, setFetchingData] = useState(false);
	const [filteredSites, setFilteredSites] = useState([]);

	const changeMonitoringFilter = (e) => {
		setFilterMonitoringType(e.target.value);
		setMonitoringFilter(e.target.value);
	};

	const changePTOFilter = (e) => {
		setPtoFilter(e.target.value);
	};

	useEffect(() => {
		if (!user || !api || !token || id) {
			return;
		}
		setFetchingData(true);
		fetch(api + "/monitoring/all", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			body: JSON.stringify({ email: user.email }),
		})
			.then((res) => {
				if (res.ok) {
					setFetchingData(false);
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					setMonitoringOptions(jsonData.monitoringOptions);
					setFilteredSites(jsonData.sites);
				}
			});
	}, [api, id, token, user]);

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		if (!user || !api || !token || !id) {
			return;
		}
		setFetchingData(true);
		fetch(api + "/monitoring/" + id, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: "Bearer " + token,
			},
			signal,
			body: JSON.stringify({ email: user.email }),
		})
			.then((res) => {
				if (res.ok) {
					setFetchingData(false);
					return res.json();
				}
			})
			.then((jsonData) => {
				if (jsonData) {
					let newParent;
					newParent = jsonData.parent;
					if (newParent.presentMonitoringTypes && newParent.presentMonitoringTypes.length > 1) {
						setMonitoringOptions(["All", ...newParent.presentMonitoringTypes]);
						setFilterMonitoringType("All");
					}
					setParent([newParent]);
				}
			});
		return () => controller.abort();
	}, [api, id, token, user]);

	const exportData = (startDate, endDate, monitoringFilter, exportLevel, ptoFilter, granularity) => {
		if (startDate && !endDate) {
			setErrorText("Please enter an end date");
			return;
		}
		if (endDate && !startDate) {
			setErrorText("Please enter a start date");
			return;
		}
		if (startDate > endDate) {
			setErrorText("Start date must be before end date");
			return;
		}
		if (startDate > new Date().toISOString().split("T")[0] || endDate > new Date().toISOString().split("T")[0]) {
			setErrorText("Dates must be before today");
			return;
		}
		// if (fetchingExportData) {
		// 	return;
		// }
		if (parent.length > 0 || filteredSites.length > 0) {
			// const csvString = convertToCSV(combineAndSumData(children));
			// setFetchingExportData(true);
			setErrorText("");
			fetch(api + "/monitoring/export", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					startDate,
					endDate,
					monitoringType: filteredSites.length > 0 ? monitoringFilter : filterMonitoringType ? filterMonitoringType : parent[0].monitoringType,
					parentSites: parent.length > 0 ? [parent[0].id] : filteredSites.map((mapData) => mapData.id),
					exportLevel,
					ptoFilter,
					granularity,
					systemTurnOnDates:
						parent.length > 0
							? parent[0].x_studio_system_turned_on_date_p
							: filteredSites.map((mapData) => ({
									id: mapData.id,
									date: mapData.x_studio_system_turned_on_date_p,
							  })),
				}),
			})
				.then((res) => {
					if (res.ok) {
						return res.json();
					} else {
						// setFetchingExportData(false);
					}
				})
				.then((jsonData) => {
					const csvString = formatCSV(jsonData.csvData, startDate, endDate, exportLevel, granularity);
					if (parent.length > 0) {
						downloadCSV(
							csvString,
							`${parent[0].x_studio_customer_portal_alias ? parent[0].x_studio_customer_portal_alias : parent[0].name} - monitoring.csv`
						);
					} else {
						downloadCSV(csvString, `monitoring.csv`);
					}
					// setFetchingExportData(false);
				});
		}
	};

	return (
		<>
			<div
				className={`${
					user.email === "ps@1stle.com"
						? "bg-PSBackgroundImage"
						: user.email === "ess@1stle.com"
						? "bg-ESSBackgroundImage"
						: isCommercial
						? "bg-CommercialBackgroundImage"
						: "bg-1stleBackgroundImage"
				} fixed top-0 left-0 right-0 bottom-0 z-[-1] bg-cover`}></div>

			<div className={sidebarOpen ? "md:ml-64" : "md:ml-10"}>
				<div className="m-10 p-4 bg-slate-50 bg-opacity-95">
					<div
						className={`p-3 flex items-center justify-center gap-2 w-32 cursor-pointer ${
							user.email === "ps@1stle.com"
								? "bg-[#FFAC1C] hover:bg-orange-500"
								: user.email === "ess@1stle.com"
								? "bg-lime-400 hover:bg-lime-500"
								: "bg-[#FFEA00] hover:bg-yellow-400"
						}`}
						onClick={() => (window.history.state && window.history.state.idx > 0 ? navigate(-1) : navigate("/monitoring"))}>
						<IoArrowBackCircle className="text-2xl cursor-pointer" />
						<p className="cursor-pointer">Go back</p>
					</div>
					{fetchingData ? (
						<div className="m-4 flex flex-col justify-center items-center">
							<LoadingCircle />
							<p className="font-bold">Retrieving your data...</p>
						</div>
					) : (
						<>
							<div className="flex justify-center items-center mb-8">
								{parent.length > 0 ? (
									<p className="text-2xl md:text-3xl font-bold">
										Monitoring Export -{" "}
										{parent[0].x_studio_customer_portal_alias ? parent[0].x_studio_customer_portal_alias : parent[0].name}
									</p>
								) : (
									<p className="text-2xl md:text-3xl font-bold">Monitoring Export - Fleet</p>
								)}
							</div>
							<div className="flex flex-col items-center justify-center mb-4">
								<div className="flex items-center justify-center mb-2 w-full max-w-md">
									<label htmlFor="start-date" className="text-lg font-bold mr-2 w-1/3">
										Start Date:{" "}
									</label>
									<input
										type="date"
										id="start-date"
										className="p-2 border border-black rounded-lg w-1/3"
										onChange={(e) => setStartDate(e.target.value)}
									/>
								</div>
							</div>
							<div className="flex flex-col items-center justify-center mb-4">
								<div className="flex items-center justify-center mb-2 w-full max-w-md">
									<label htmlFor="end-date" className="text-lg font-bold mr-2 w-1/3">
										End Date:{" "}
									</label>
									<input
										type="date"
										id="end-date"
										className="p-2 border border-black rounded-lg w-1/3"
										onChange={(e) => setEndDate(e.target.value)}
									/>
								</div>
							</div>
							<div className="flex flex-col items-center justify-center mb-4">
								<div className="flex items-center justify-center mb-2 w-full max-w-md">
									<label htmlFor="export-level" className="text-lg font-bold mr-2 w-1/3">
										Export Level:{" "}
									</label>
									<select
										value={exportLevel}
										onChange={(e) => setExportLevel(e.target.value)}
										className="p-2 border border-black rounded-lg w-1/3">
										{exportOptions.map((option, index) => (
											<option key={index} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							</div>
							{parent.length > 0 && monitoringOptions && monitoringOptions.length > 0 ? (
								<FilterComponent
									title={"Monitoring Types"}
									filter={filterMonitoringType}
									options={monitoringOptions}
									changeFilter={changeMonitoringFilter}
								/>
							) : null}
							{filteredSites.length > 0 && monitoringOptions && monitoringOptions.length > 0 ? (
								<FilterComponent
									title={"Monitoring Types"}
									filter={monitoringFilter}
									options={monitoringOptions}
									changeFilter={changeMonitoringFilter}
								/>
							) : null}
							{filteredSites.length > 0 ? (
								<FilterComponent
									title={"PTO"}
									changeFilter={changePTOFilter}
									filter={ptoFilter}
									options={ptoOptions}
									requiredUserEmail={"ps@1stle.com"}
									userEmail={user.email}
								/>
							) : null}
							<div className="flex flex-col items-center justify-center mb-4">
								<div className="flex items-center justify-center mb-2 w-full max-w-md">
									<label htmlFor="granularity" className="text-lg font-bold mr-2 w-1/3">
										Granularity:{" "}
									</label>
									<select
										value={granularity}
										onChange={(e) => setGranularity(e.target.value)}
										className="p-2 border border-black rounded-lg w-1/3">
										{granularityOptions.map((option, index) => (
											<option key={index} value={option}>
												{option}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="flex flex-col items-center justify-center mb-4">
								{errorText ? <p className="text-red-500 text-sm mb-4">{errorText}</p> : null}
							</div>
							<div className="flex items-center justify-center w-full mb-4">
								<button
									onClick={() => exportData(startDate, endDate, monitoringFilter, exportLevel, ptoFilter, granularity)}
									className="bg-blue-500 text-white px-4 py-2 rounded-lg">
									Export
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}
