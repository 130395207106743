import { useAuth0 } from "@auth0/auth0-react";
import { CodeBlock, dracula } from "react-code-blocks";
import JSONPretty from "react-json-pretty";

const DataDocs = () => {
	const { user } = useAuth0();
	return (
		<section>
			<h2 className="p-4 bg-slate-200 font-bold text-3xl">Get Meter Data</h2>
			<div className="p-4">
				<div className="mt-4 flex flex-col gap-4">
					<div className="mb-4">
						<p>Get meter data via varying filters.</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Definition</p>
						<p>
							<span className="bg-gray-200">{`/data`}</span>
						</p>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Arguments</p>
						<div className="grid grid-cols-4 border border-black">
							<p className="p-1 bg-slate-200 border-b border-black">Argument</p>
							<p className="p-1 bg-slate-200 border-b border-black">Description</p>
							<p className="p-1 bg-slate-200 border-b border-black">Required?</p>
							<p className="p-1 bg-slate-200 border-b border-black">Default</p>
							<p className="p-1">startDate</p>
							<div className="p-1">
								<p>When this is used, you must also add an end date. If these aren't used, you will get all available data.</p>
								<p className="mt-1">{`ex: 12-24-2024`}</p>
							</div>
							<p className="p-1">no</p>
							<p className="p-1"></p>
							<p className="p-1 bg-slate-200">endDate</p>
							<div className="p-1 bg-slate-200">
								<p>When this is used, you must also add a start date. If these aren't used, you will get all data from all time. </p>
								<p className="mt-1">{`ex: 12-24-2024`}</p>
							</div>
							<p className="p-1 bg-slate-200">no</p>
							<p className="p-1 bg-slate-200"></p>
							<p className="p-1">sites</p>
							<div className="p-1">
								<p>Will filter the results to only return data for these sites</p>
							</div>
							<p className="p-1">no</p>
							<p className="p-1"></p>
							<p className="p-1 bg-slate-200">type</p>
							<div className="p-1 bg-slate-200">
								<p>Will filter the results to only return data for the selected monitoring type.</p>
								<p className="mt-3">{`Options: ["All", "Locus", "Landis"]`}</p>
							</div>
							<p className="p-1 bg-slate-200">no</p>
							<p className="p-1 bg-slate-200">All</p>

							<p className="p-1 ">exportLevel</p>
							<div className="p-1 ">
								<p>{`Will modify the results to return data based on the meter it is related to or aggregate the meter info by site.`}</p>
								<p className="mt-3">{`Options: ["Sites", "Meters"]`}</p>
							</div>
							<p className="p-1 ">no</p>
							<p className="p-1 ">Meters</p>
							<p className="p-1 bg-slate-200">granularity</p>
							<div className="p-1 bg-slate-200">
								<p>{`Will modify the grouping of the results based on time. `}</p>
								<p className="mt-3">{`Options: ["Yearly", "Monthly", "Daily"]`}</p>
							</div>
							<p className="p-1 bg-slate-200">no</p>
							<p className="p-1 bg-slate-200">Daily</p>
							{user.email === "ps@1stle.com" ? (
								<>
									<p className="p-1 ">pto</p>
									<div className="p-1 ">
										<p>{`Will filter meters based on whether the meter has already been PTO approved or not.`}</p>
										<p className="mt-3">{`Options: ["All", "PTO Approved", "No PTO"]`}</p>
									</div>
									<p className="p-1 ">no</p>
									<p className="p-1 ">All</p>
								</>
							) : null}
						</div>
					</div>
					<div className="mb-4">
						<p className="font-bold mb-2 text-lg">Returns</p>
						<p>
							<span className="mr-1 font-bold">data:</span> A list of all data that meets the search results. Includes the collection date,
							hardware id and type, collected data, and related data (name of related meter or site based on exportLevel, meter_id, site_id)
						</p>
					</div>

					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example Request</div>
						<div className="p-2">
							<p>POST /data</p>
							<p>{`headers: {"Authorization": "Bearer YOUR_TOKEN"}`}</p>
							<div className="flex">
								<p className="mr-2">body: </p>
								<JSONPretty
									data={{
										startDate: "12-10-2024",
										endDate: "12-11-2024",
										sites: [2],
										type: "Example",
										exportLevel: "Sites",
										granularity: "Daily",
										...(user.email === "ps@1stle.com" ? { pto: "PTO Approved" } : {}),
									}}
								/>
							</div>
						</div>
					</div>
					<div className="bg-black text-white border border-black ">
						<div className="p-2 bg-gray-600 text-white">Example JSON Response</div>
						<div className="p-2">
							<JSONPretty
								data={{
									data: [
										{
											date: "2024-12-10",
											hardware_id: 1234,
											hardware_type: "Example",
											...(user.email === "ps@1stle.com" ? { kwh_expected: 1234 } : {}),
											kwh_measured: 1234,
											meter_id: 1,
											name: "Example",
											site_id: 2,
										},
										{
											date: "2024-12-11",
											hardware_id: 1234,
											hardware_type: "Example",
											...(user.email === "ps@1stle.com" ? { kwh_expected: 4321 } : {}),
											kwh_measured: 4321,
											meter_id: 1,
											name: "Example",
											site_id: 2,
										},
									],
								}}
							/>
						</div>
					</div>
					<hr className="border border-gray-400 mt-4" />
					<div className="mt-4">
						<p className="font-bold text-lg">Example Implementation</p>
						<p>
							This example will show you how to get meter data via python. The results for this query are not limited and will return all results.
						</p>
						<div className="bg-black text-white border border-black mt-4 mb-4">
							<CodeBlock
								text={`import requests
import json

// token code

token = get_token()

def get_data():
	route='/data' 
	headers = {'Authorization': 'Bearer ' + token}
	json_body = {
	    'startDate': '12-10-2024',
	    'endDate': '12-15-2024',
	    'sites': [1, 2],
	    'exportLevel': 'Meters',
		# You can put other arguments here
	}

	res = requests.post(BASE_URL+route, headers=headers, json=json_body)

	if res.status_code != 200:
		raise Exception(f"API call failed with status code {res.status_code}: {res.text}")

	res_data = res.json()
	data = res_data.get('data', False)
	if(data):
		return data
	else:
		raise Exception('Meter not found')

data = get_data()`}
								language="python"
								theme={dracula}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DataDocs;
