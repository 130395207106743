const FilterComponent = ({
	userEmail = "",
	requiredUserEmail = "",
	filter,
	options,
	changeFilter,
	useVerticalLayout = false,
	title,
	blankOptionText = "No Option Set",
}) => {
	if (requiredUserEmail && requiredUserEmail !== userEmail) {
		return null;
	}
	if (!options || options.length === 0) {
		return null;
	}
	return (
		<div className={`${useVerticalLayout ? "" : "flex flex-col items-center justify-center mb-4"}`}>
			<div className={`${useVerticalLayout ? "" : "flex items-center justify-center mb-2 w-full max-w-md"}`}>
				<p className={`${useVerticalLayout ? "text-center" : "text-lg font-bold mr-2 w-1/3"} font-bold mb-1`}>{`${
					useVerticalLayout ? title : title + ":"
				}`}</p>
				<select value={filter} onChange={changeFilter} className={`${useVerticalLayout ? "w-full " : "w-1/3"} p-2 border border-black rounded-lg `}>
					{options.map((option, index) => (
						<option key={index} value={option}>
							{option ? option : blankOptionText}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default FilterComponent;
