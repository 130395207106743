import { CodeBlock, dracula } from "react-code-blocks";

const Authentication = () => {
	return (
		<section>
			<h2 className="p-4 bg-slate-200 font-bold text-3xl">How to Authenticate</h2>
			<div className="p-4">
				<p className="mt-4">This API uses an access token approach. For every request you make, you must return that access token in the headers.</p>
				<div className="mt-4">
					<p className="font-bold text-lg">Getting your Token</p>
					<p>
						To get your token, you must make a POST request to the /token route with the email and password you use to login to the customer portal
						in the body. This will return your access token.
					</p>
					<p>
						Note: Access Tokens have a lifespan of 2 hours. Once expired, you must call this route again to get a new token. Otherwise, your old
						token will throw an error.
					</p>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example Request</div>
						<div className="p-2">
							<p>POST /token</p>
							<p>{`Body: {email: YOUR_EMAIL, password: YOUR_PASSWORD}`}</p>
						</div>
					</div>
					<div className="bg-black text-white border border-black mt-4">
						<div className="p-2 bg-gray-600 text-white">Example JSON Response</div>
						<div className="p-2">
							<p>{`{"token": "EXAMPLE_TOKEN"}`}</p>
						</div>
					</div>
				</div>

				<hr className="border border-gray-400 mt-4" />
				<div className="mt-4">
					<p className="font-bold text-lg">Using your Token</p>
					<p>
						{`This API uses an token authentication approach. The format is to place it in the authorization portion of the header with the format of {Bearer + YOUR_TOKEN} as seen in the example below.`}
					</p>
					<div className="bg-black text-white border border-black mt-4 mb-4">
						<div className="p-2 bg-gray-600 text-white">Example Request</div>
						<div className="p-2">
							<p>GET /sites</p>
							<p>{`headers: {"Authorization": "Bearer YOUR_TOKEN"}`}</p>
						</div>
					</div>
				</div>

				<hr className="border border-gray-400 mt-4" />
				<div className="mt-4">
					<p className="font-bold text-lg">Example Implementation</p>
					<p>
						This example will show you how to get your token in python. To reduce clutter, we'll not add it to other examples but a token will be
						needed for any other example.
					</p>
					<div className="bg-black text-white border border-black mt-4 mb-4">
						<CodeBlock
							text={`import requests
import json

email = '' # INSERT EMAIL OR REFERENCE TO ENV VARIABLES HERE
password = '' # INSERT EMAIL HERE OR REFERENCE TO ENV VARIABLES HERE

BASE_URL = '${process.env.REACT_APP_PUBLIC_API_URL}/api/v1'
token = ''


def get_token():
	token_route = '/token'
	body = {
		'email': email,
		'password': password
	}
	res = requests.post(BASE_URL + token_route, data=body)
	if(res.status_code == 200):
		response_data = json.loads(res.text)
		return response_data['token']
	else:
		print('Something went wrong. Please make sure your email and password are correct.')
		return ''

token = get_token()`}
							language="python"
							theme={dracula}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Authentication;
