import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import {
	FaAngleUp,
	FaAngleDown,
	FaHome,
	FaCalendarCheck,
	FaExclamationCircle,
	FaFolderOpen,
	FaQuestionCircle,
	FaSignOutAlt,
	FaSolarPanel,
	FaBell,
	FaDatabase,
} from "react-icons/fa";
import { LogoutButtonDialogBox } from "./LogoutButtonDialogBox";

export const NavBar = ({ api, sidebarOpen, setSidebarOpen, token, isCommercial, API_WHITELIST }) => {
	const { user, isAuthenticated, logout } = useAuth0();
	const [installationMenuOpen, setInstallationMenuOpen] = useState(false);
	const [supportMenuOpen, setSupportMenuOpen] = useState(false);
	const [resourcesMenuOpen, setResourcesMenuOpen] = useState(false);
	const [showLogout, setShowLogout] = useState(false);
	const [installation, setInstallation] = useState([]);
	const [sites, setSites] = useState([]);
	const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
	const [monitoringSubscription, setMonitoringSubscription] = useState(false);

	useEffect(() => {
		if (api === "" || !isAuthenticated || !token || isCommercial) {
			return;
		}

		try {
			fetch(api + "/installation", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					userEmail: user.email,
				}),
				mode: "cors",
			})
				.then((res) => {
					return res.json();
				})
				.then((res) => {
					setInstallation(res.installation);
					setMonitoringSubscription(res.monitoringSubscription);
				});
		} catch (error) {}
	}, [api, isAuthenticated, token, user.email, isCommercial]);

	useEffect(() => {
		if (!user || !api || !token || isCommercial) {
			return;
		}

		try {
			fetch(api + "/monitoring/all", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({ email: user.email }),
			})
				.then((res) => {
					if (res.ok) {
						return res.json();
					}
				})
				.then((jsonData) => {
					if (jsonData) {
						setSites(jsonData.sites);
					}
				});
		} catch (error) {}
	}, [api, token, user, isCommercial]);

	const handleLogout = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	};

	const handleSupportMenu = (e) => {
		e.stopPropagation();
		setSupportMenuOpen(!supportMenuOpen);
	};

	const handleResourceMenu = (e) => {
		e.stopPropagation();
		setResourcesMenuOpen(!resourcesMenuOpen);
	};

	return (
		isAuthenticated && (
			<div>
				<div
					className="fixed top-0 inline-flex items-center p-2 mt-2 ms-1 text-sm text-white bg-black rounded-lg md:hidden z-50 hover:bg-lime-400 focus:outline-none focus:ring-2 focus:ring-lime-400"
					onClick={() => setIsMobileNavOpen(true)}>
					<BiMenu size={15} />
				</div>

				{isMobileNavOpen && (
					<div
						className={`fixed inset-0 bg-black bg-opacity-80 z-[500] ${isCommercial ? "bg-black" : "bg-sky-500"}`}
						onClick={() => setIsMobileNavOpen(false)}>
						<div className="container mx-auto flex justify-center items-center h-full overflow-auto">
							<div className="flex flex-col justify-between gap-2 list-none">
								<Link className="flex items-center justify-center mb-5" to="/monitoring">
									<img src={process.env.REACT_APP_1STLE_NAV_LOGO} className="h-10 w-auto" alt="1ST LIGHT LOGO" />
								</Link>
								{isCommercial ? (
									<div className="space-y-2">
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/monitoring">
												<FaSolarPanel className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Monitoring</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/installation">
												<FaHome className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Installs</span>
											</NavLink>
										</li>
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/services">
												<FaCalendarCheck className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Services</span>
											</NavLink>
										</li>

										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/alerts">
												<FaBell className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Alerts</span>
											</NavLink>
										</li>

										{API_WHITELIST.includes(user.email) ? (
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
															: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
													}
													to="/api/v1">
													<FaDatabase className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
													<span className="ms-3">API</span>
												</NavLink>
											</li>
										) : null}

										<li>
											<Link
												className="flex items-center p-2 text-white rounded-lg hover:bg-lime-400 w-56 group"
												onClick={() => setShowLogout(true)}>
												<FaSignOutAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Log Out</span>
											</Link>
										</li>
									</div>
								) : (
									<div className="space-y-2">
										{monitoringSubscription === true ? (
											<li>
												{sites.map((site, index) => (
													<div key={index}>
														<NavLink
															className={({ isActive }) =>
																isActive
																	? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
																	: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
															}
															to={"/monitoring/" + site.id}>
															<FaSolarPanel className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
															<span className="ms-3">Monitoring</span>
														</NavLink>
													</div>
												))}
											</li>
										) : null}
										{installation && installation.length > 1 ? (
											<li>
												<button
													onClick={() => setInstallationMenuOpen(!installationMenuOpen)}
													type="button"
													className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
													aria-controls="installationdropdown"
													data-collapse-toggle="installationdropdown">
													<FaHome className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
													<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">My Install</span>
													{installationMenuOpen ? <FaAngleUp className="ml-2" /> : <FaAngleDown className="ml-2" />}
												</button>
												<ul id="installationdropdown" className={`py-2 space-y-2 ${!installationMenuOpen && "hidden"}`}>
													{installation.map((install, index) => (
														<div key={index}>
															<Link
																className="flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
																to={"/installation/" + install.id}>
																{install.x_studio_site_street}
															</Link>
														</div>
													))}
												</ul>
											</li>
										) : (
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
															: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
													}
													to="/installation">
													<FaHome className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
													<span className="ms-3">My Install</span>
												</NavLink>
											</li>
										)}
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/services/select-address">
												<FaCalendarCheck className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Services</span>
											</NavLink>
										</li>
										<li>
											<button
												onClick={handleSupportMenu}
												type="button"
												className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
												aria-controls="supportdropdown"
												data-collapse-toggle="supportdropdown">
												<FaExclamationCircle className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">System Support</span>
												{supportMenuOpen ? <FaAngleUp className="ml-2" /> : <FaAngleDown className="ml-2" />}
											</button>
											<ul id="supportdropdown" className={`py-2 space-y-2 ${!supportMenuOpen && "hidden"}`}>
												<li>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
																: "flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														}
														to="/tickets">
														View Tickets
													</NavLink>
												</li>
												<li>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
																: "flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														}
														to="/submitticket">
														Submit a Ticket
													</NavLink>
												</li>
											</ul>
										</li>
										<li>
											<button
												onClick={handleResourceMenu}
												type="button"
												className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
												aria-controls="resourcesdropdown"
												data-collapse-toggle="resourcesdropdown">
												<FaFolderOpen className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Resources</span>
												{resourcesMenuOpen ? <FaAngleUp className="ml-2" /> : <FaAngleDown className="ml-2" />}
											</button>
											<ul id="resourcesdropdown" className={`py-2 space-y-2 ${!resourcesMenuOpen && "hidden"}`}>
												<li>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
																: "flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														}
														to="/about">
														About Solar - 1LE
													</NavLink>
												</li>
												<li>
													<a
														className="flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														href="https://www.irs.gov/forms-pubs/about-form-5695"
														target="_blank"
														rel="noreferrer">
														Federal Tax Credit
													</a>
												</li>
												<li>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
																: "flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														}
														to="/transferownershipform">
														Transfer Ownership Form
													</NavLink>
												</li>
												<li>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
																: "flex items-center w-full p-2 text-sm text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
														}
														to="/creditcardform">
														Credit Card Form
													</NavLink>
												</li>
											</ul>
										</li>
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/contact">
												<FaQuestionCircle className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Contact Us</span>
											</NavLink>
										</li>

										<li>
											<Link
												className="flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												onClick={() => setShowLogout(true)}>
												<FaSignOutAlt className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">Log Out</span>
											</Link>
										</li>
									</div>
								)}
							</div>
						</div>
					</div>
				)}

				<aside
					id="logo-sidebar"
					className={`${
						sidebarOpen
							? "fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
							: "fixed top-0 left-0 z-40 w-10 h-screen transition-transform -translate-x-full sm:translate-x-0"
					} hidden md:block`}
					aria-label="Sidebar">
					<div className={`h-full px-3 py-4 overflow-y-auto overflow-x-hidden ${isCommercial ? "bg-black" : "bg-sky-500"}`}>
						<div
							className={`${
								sidebarOpen
									? "flex flex-row items-center -space-y-6 space-x-5"
									: "flex flex-row items-center justify-center -space-y-10 -space-x-3"
							}`}>
							<Link className="flex items-center ps-2.5 mb-5" to="/monitoring">
								<img src={process.env.REACT_APP_1STLE_NAV_LOGO} className={`${sidebarOpen ? "h-auto me-3" : "hidden"}`} alt="1ST LIGHT LOGO" />
							</Link>
							<button onClick={() => setSidebarOpen(!sidebarOpen)}>
								<BiMenu
									className={`${
										sidebarOpen
											? `w-10 h-10 transition duration-75 ${isCommercial ? "text-gray-500" : "text-gray-200"}`
											: `w-10 h-10 mt-10 transition duration-75 ${isCommercial ? "text-gray-500" : "text-gray-200"}`
									}`}
								/>
							</button>
						</div>
						<ul className="space-y-2 font-medium">
							{isCommercial ? (
								<div className={`${sidebarOpen ? "flex flex-col justify-between gap-5" : "hidden"}`}>
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/monitoring">
											<FaSolarPanel className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Monitoring</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/installation">
											<FaHome className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Installs</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/services">
											<FaCalendarCheck className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Services</span>
										</NavLink>
									</li>
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/alerts">
											<FaBell className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Alerts</span>
										</NavLink>
									</li>

									{API_WHITELIST.includes(user.email) ? (
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/api/v1">
												<FaDatabase className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">API</span>
											</NavLink>
										</li>
									) : null}

									<li className="fixed bottom-10">
										<Link
											className="flex items-center p-2 text-white rounded-lg hover:bg-lime-400 w-56 group"
											onClick={() => setShowLogout(true)}>
											<FaSignOutAlt className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Log Out</span>
										</Link>
									</li>
								</div>
							) : (
								<div className={`${sidebarOpen ? "flex flex-col justify-between gap-5" : "hidden"}`}>
									{monitoringSubscription === true ? (
										<li>
											{sites.map((site, index) => (
												<div key={index}>
													<NavLink
														className={({ isActive }) =>
															isActive
																? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
																: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
														}
														to={"/monitoring/" + site.id}>
														<FaSolarPanel className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
														<span className="ms-3">Monitoring</span>
													</NavLink>
												</div>
											))}
										</li>
									) : null}
									{installation && installation.length > 1 ? (
										<li>
											<button
												onClick={() => setInstallationMenuOpen(!installationMenuOpen)}
												type="button"
												className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
												aria-controls="installationdropdown"
												data-collapse-toggle="installationdropdown">
												<FaHome className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">My Install</span>
												{installationMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
											</button>
											<ul id="installationdropdown" className={`py-2 space-y-2 ${!installationMenuOpen && "hidden"}`}>
												{installation.map((install, index) => (
													<div key={index}>
														<Link
															className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
															to={"/installation/" + install.id}>
															{install.x_studio_site_street}
														</Link>
													</div>
												))}
											</ul>
										</li>
									) : (
										<li>
											<NavLink
												className={({ isActive }) =>
													isActive
														? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
														: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
												}
												to="/installation">
												<FaHome className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
												<span className="ms-3">My Install</span>
											</NavLink>
										</li>
									)}
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/services/select-address">
											<FaCalendarCheck className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Services</span>
										</NavLink>
									</li>
									<li>
										<button
											onClick={() => setSupportMenuOpen(!supportMenuOpen)}
											type="button"
											className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
											aria-controls="supportdropdown"
											data-collapse-toggle="supportdropdown">
											<FaExclamationCircle className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
											<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">System Support</span>
											{supportMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
										</button>
										<ul id="supportdropdown" className={`py-2 space-y-2 ${!supportMenuOpen && "hidden"}`}>
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
															: "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													}
													to="/tickets">
													View Tickets
												</NavLink>
											</li>
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
															: "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													}
													to="/submitticket">
													Submit a Ticket
												</NavLink>
											</li>
										</ul>
									</li>
									<li>
										<button
											onClick={() => setResourcesMenuOpen(!resourcesMenuOpen)}
											type="button"
											className="flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-lime-400"
											aria-controls="resourcesdropdown"
											data-collapse-toggle="resourcesdropdown">
											<FaFolderOpen className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
											<span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Resources</span>
											{resourcesMenuOpen ? <FaAngleUp /> : <FaAngleDown />}
										</button>
										<ul id="resourcesdropdown" className={`py-2 space-y-2 ${!resourcesMenuOpen && "hidden"}`}>
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
															: "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													}
													to="/about">
													About Solar - 1LE
												</NavLink>
											</li>
											<li>
												<a
													className="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													href="https://www.irs.gov/forms-pubs/about-form-5695"
													target="_blank"
													rel="noreferrer">
													Federal Tax Credit
												</a>
											</li>
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
															: "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													}
													to="/transferownershipform">
													Transfer of Ownership Form
												</NavLink>
											</li>
											<li>
												<NavLink
													className={({ isActive }) =>
														isActive
															? `flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400 bg-lime-400`
															: "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-lime-400"
													}
													to="/creditcardform">
													Credit Card Authorization Form
												</NavLink>
											</li>
										</ul>
									</li>
									<li>
										<NavLink
											className={({ isActive }) =>
												isActive
													? `flex items-center p-2 text-white rounded-lg bg-lime-400 hover:bg-lime-400 group`
													: "flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											}
											to="/contact">
											<FaQuestionCircle className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Contact Us</span>
										</NavLink>
									</li>
									<li
										className={
											(resourcesMenuOpen && supportMenuOpen) ||
											(resourcesMenuOpen && installationMenuOpen) ||
											(resourcesMenuOpen && monitoringSubscription)
												? "relative bottom-auto"
												: "fixed bottom-10 w-56"
										}>
										<Link
											className="flex items-center p-2 text-white rounded-lg hover:bg-lime-400 group"
											onClick={() => setShowLogout(true)}>
											<FaSignOutAlt className="flex-shrink-0 w-5 h-5 text-gray-200 transition duration-75 group-hover:text-gray-900" />
											<span className="ms-3">Log Out</span>
										</Link>
									</li>
								</div>
							)}
						</ul>
					</div>
				</aside>
				{showLogout ? <LogoutButtonDialogBox confirmAction={handleLogout} cancelAction={() => setShowLogout(false)} /> : ""}
			</div>
		)
	);
};
